import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { ROUTES } from '../../routes';

const PaginationContainer = styled.nav`
  text-align: center;

  a {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell;
    background: #fff;
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    margin: 0 4px;
    box-shadow: rgba(39, 44, 49, 0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
    border-radius: 6px;
    margin-bottom: 5px;
    min-width: 50px;

    &.active {
      -webkit-box-shadow: inset 3px 0px 0px 0px #0a0b0c;
      -moz-box-shadow: inset 3px 0px 0px 0px #0a0b0c;
      box-shadow: inset 3px 0px 0px 0px #0a0b0c;
    }

    &:hover:not(.active) {
      background-color: #ddd;
    }

    &:hover {
      text-decoration: none;
    }
  }
`;

const NavItems = styled.div`
  display: inline-block;
`;

const Pagination = ({ currentPage, numPages }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '' : `${(currentPage - 1).toString()}`;
  const nextPage = (currentPage + 1).toString();

  return (
    <PaginationContainer>
      <NavItems>
        {!isFirst && (
          <Link to={ROUTES.BLOG.toUrl(prevPage)} rel="prev">
            {/* << symbol */}
            {String.fromCharCode(171)}
          </Link>
        )}

        {Array.from({ length: numPages }, (_, i) => (
          <Link
            key={`pagination-number${i + 1}`}
            className={i + 1 === currentPage ? 'active' : ''}
            to={ROUTES.BLOG.toUrl(i + 1)}
          >
            {i + 1}
          </Link>
        ))}

        {!isLast && (
          <Link to={ROUTES.BLOG.toUrl(nextPage)} rel="next">
            {/* >> symbol */}
            {String.fromCharCode(187)}
          </Link>
        )}
      </NavItems>
    </PaginationContainer>
  );
};

export default Pagination;
