import React from 'react';
import styled, { css } from 'styled-components';
import { getSrc } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

import Footer from '../blog/components/Footer';
import IndexLayout from '../layouts';
import Pagination from '../blog/components/Pagination';
import PostCard from '../blog/components/PostCard';
import SiteNav from '../blog/components/Header/SiteNav';
import Wrapper from '../blog/components/Wrapper/Wrapper';

import Logo from '../blog/assets/main/logo';
import MetaData from '../containers/MetaData';

import { blog } from '../website-config';
import {
  BackgroundOverlay,
  inner,
  PostFeed,
  SiteDescription,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
} from '../styles/shared';

const HomePosts = css`
  @media (min-width: 795px) {
    .post-card:nth-of-type(6n + 1):not(.no-image) {
      flex: 1 1 100%;
      flex-direction: row;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      border-radius: 5px 0 0 5px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content {
      flex: 0 1 357px;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) h2 {
      font-size: 2.6rem;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) p {
      font-size: 1.8rem;
      line-height: 1.55em;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-content-link {
      padding: 30px 40px 0;
    }

    .post-card:nth-of-type(6n + 1):not(.no-image) .post-card-meta {
      padding: 0 40px 30px;
    }
  }
`;

const MainLogo = styled.div`
  position: relative;
  width: 230px;
  display: flex;
  justify-content: center;
  height: 60px;

  svg {
    position: absolute;
    left: 0;
    width: 60px;
    fill: #ababab;
    stroke: #000;

    stroke-width: 5px;
    stroke-opacity: 0.2;
  }
`;

const LogoInitial = styled.div`
  position: absolute;
  top: 9px;
  left: 5px;
  width: 215px;
  color: #fff;
  font-family: poppins;
  font-weight: 200;
  font-size: 36px;
`;

const IndexPage = ({ children, data, pageContext }) => {
  const width = data.header.childImageSharp.gatsbyImageData.width;
  const height = data.header.childImageSharp.gatsbyImageData.height;
  const relativeImgUrl = getSrc(data.header.childImageSharp);

  return (
    <IndexLayout css={HomePosts} >
      <MetaData location={blog} height={height} image={relativeImgUrl} page={pageContext.currentPage} robots="index, follow" width={width} />
      <Wrapper>
        <SiteHeader bgImg={relativeImgUrl} padding='0px'>
          <BackgroundOverlay>
            <div css={inner}>
              <SiteHeaderContent>
                <SiteTitle>
                  <MainLogo>
                    <Logo />
                    <LogoInitial>David's Blog</LogoInitial>
                  </MainLogo>
                </SiteTitle>
                <SiteDescription>{blog.heading}</SiteDescription>
              </SiteHeaderContent>
              <SiteNav isHome />
            </div>
          </BackgroundOverlay>
        </SiteHeader>
        <SiteMain useOuter>
          <div css={inner}>
            <PostFeed>
              {data.allMarkdownRemark.edges.map(post => {
                // filter out drafts in production
                return (
                  (post.node.frontmatter.draft !== true ||
                    process.env.NODE_ENV !== 'production') && (
                    <PostCard key={post.node.fields.slug} post={post.node} />
                  )
                );
              })}
            </PostFeed>
          </div>
        </SiteMain>
        {children}
        <Pagination currentPage={pageContext.currentPage} numPages={pageContext.numPages} />
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    header: file(relativePath: { eq: "blog/assets/main/blog-cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000)
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: { frontmatter: { draft: { ne: true } } },
      limit: $limit,
      skip: $skip
    ) {
      edges {
        node {
          timeToRead
          frontmatter {
            title
            date
            tags
            draft
            userDate: date(formatString: "MMMM DD, YYYY")
            image {
              childImageSharp {
                gatsbyImageData(width: 3720)
              }
            }
            author {
              name
              bio
              avatar {
                children {
                  ... on ImageSharp {
                    gatsbyImageData(quality: 90)
                  }
                }
              }
            }
          }
          excerpt
          fields {
            layout
            slug
          }
        }
      }
    }
  }
`;
